import React from 'react';

interface PropsTextComponent {
    text: string
}

const TextComponent = ({ text }:PropsTextComponent) => {
    const formattedContent = text.split(/\*(.*?)\*/).map((fragment, index) => {
        if (index % 2 === 1) {
            return <span key={index}>{fragment}</span>;
        } else {
            return fragment;
        }
    });

    return <>{formattedContent}</>;
}

export default TextComponent;
