
import './style.scss'
interface PropsContainerCardWithCardTitle {
    title:string
    items: string[]
    iconsForItems: string
}

const ContainerCardWithCardTitle = ({title, items, iconsForItems}: PropsContainerCardWithCardTitle) => {
    return (
        <div>

        <div className="container-card-with-card-title">
            <div className="card-title">
                <h2>{title}</h2>
            </div>
            <div className="card-body">
                {items.map((item, index) => (
                    <div key={index} className="item">
                        <img src={iconsForItems}/>
                        <p>{item}</p>
                    </div>
                ))}
            </div>
        </div>
        </div>
    )
}

export default ContainerCardWithCardTitle;

