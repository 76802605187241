import './styles.scss'
import GoldenButton from "../GoldenButton";

interface PropsPaymentCard {
    title: string
    oldPrice: string
    installments: string
    price: string
    inCash: string
    itemsIncluded: string[]
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}
const PaymentCard = ({title, oldPrice, installments, price, inCash, itemsIncluded, onClick}: PropsPaymentCard) => {
    return (
        <div className="container-payment">
            <h2>{title}</h2>
            <div className="old-price">
                <p>De:</p>
                <h3 >{oldPrice}</h3>
            </div>
            <div>

                <p>Por: {installments}</p>
                <h3>{price}</h3>
            </div>
            <div>
                <p>{inCash}</p>
            </div>

            <div className="items-list">
                <ul>
                    {itemsIncluded.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
            <GoldenButton text={"QUERO ENTRAR AGORA"} onClick={onClick}/>
        </div>
    );
}

export default PaymentCard;