import './style.scss'
import FaqItem from "./FaqItem";
interface PropsContainerFaq {
    title: string,
    items: {question: string, answer: string}[]
}

const ContainerFaq = ({title, items}:PropsContainerFaq) => {
  return (
      <section className="faq">
          <h2>{title}</h2>
          {items && items.map((itemFaq, index) => (
              <FaqItem key={index} question={itemFaq.question} answer={itemFaq.answer} />
          ))}
      </section>
  )
}

export default ContainerFaq
