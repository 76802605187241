import './style.scss'
interface PropsGoldenButton {
    text: string
    margimBotton?: string
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}
const GoldenButton = ({text, margimBotton, onClick}: PropsGoldenButton) => {
  return (
      <button className="golden-button" style={{marginBottom:margimBotton }} onClick={(event) => {
          if (onClick) {
              onClick(event)
          }
      }}>{text}</button>
  )
}

export default  GoldenButton
