import React, { useState } from 'react';
import './style.scss'
interface VideoProps {
    posterUrl: string;
    srcUrl: string
    preLoad?: string
}

const VideoComponent: React.FC<VideoProps> = ({ posterUrl, srcUrl, preLoad = "none" }) => {
    const [hasLoaded, setHasLoaded] = useState(false);

    const handleVideoClick = () => {
        if (!hasLoaded) {
            setHasLoaded(true);
        }
    };

    return (
        <video className={"video"} controls poster={posterUrl} preload={preLoad} onClick={handleVideoClick}>
            {hasLoaded && <source src={srcUrl} type="video/mp4" />}
            <p>Seu navegador não suporta a reprodução de vídeo.</p>
        </video>
    );
};

export default VideoComponent;
