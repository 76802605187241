// AfterSubmitModal.tsx
import React from 'react';
import './style.scss';

interface AfterSubmitModalProps {
    title: string;
    description: string[];
    isOpen: boolean;
    onClose: () => void;
}
// const handleSubmit = () => {
//     const paymentURL = 'https://mpago.la/1fCGYF6';
//     try {
//         setTimeout(() => {
//             window.open(paymentURL, '_blank');
//         });
//     } catch (error) {
//         console.error('Erro ao redirecionar:', error);
//     }
// };

const AfterSubmitModal: React.FC<AfterSubmitModalProps> = ({ title, description, isOpen, onClose }) => {
    // const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     handleSubmit();
    // };

    return (
        <div className={`modal-container`} style={{ display: isOpen ? 'block' : 'none' }} >
            <div className="card-modal">
                <span className="close-btn" onClick={onClose}>
                    <img src="images/close.svg" alt="" />
                </span>
                <div className="after-submit-content">
                    <h2><span>{title}</span></h2>
                    {description.map((item, index) => (<p key={index}>{item}</p>))}
                </div>
                <a href="https://mpago.la/2tGppa2" className="button">QUERO ME INSCREVER</a>
            </div>
        </div>
    );
};

export default AfterSubmitModal;
