import './style.scss'
import TextComponent from "../TextFormatted";
import {useEffect, useState} from "react";

interface PropsBanner {
    imageUrl: string,
    title: string,
    description: string[],
    titleStyle?: {whiteSpace?: "nowrap" | "normal", fontSize?: string, width?: string}
    imageStyle?: {width: string, maxWidth: string}
}

const Banner = ({imageUrl, title, description, titleStyle, imageStyle}: PropsBanner) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const isSmallScreen = windowWidth <= 700;
    const styleTitle = {

        whiteSpace: titleStyle?.whiteSpace,
        fontSize: titleStyle?.fontSize,
        width: isSmallScreen ? '80%' : titleStyle?.width
    }

    const styleImage = {
        width: imageStyle?.width,
        maxWidth: imageStyle?.maxWidth
    }


    return (
    <div className="container-banner">
            <div>
                <img src={imageUrl} style={styleImage} alt="Banner Image"/>
                {/*<img className="logo-desafio-detox"  src="images/logo-desafio-detox.png"/>*/}
                <h1 style={styleTitle}>{title}</h1>
            </div>
            {description.map((paragraph, index) => (
                <p key={index}><TextComponent text={paragraph}/></p>
            ))}
        </div>
    );
}

export default Banner