import React, { useState } from 'react';
import './style.scss'
interface PropsFaqItem {
    question: string;
    answer: string;
}

const FaqItem: React.FC<PropsFaqItem> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDescription = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="faq-item" onClick={toggleDescription}>
            <button className={`show-description-button ${isOpen ? 'open' : ''}`} >
                <img src="/images/downarrow-green.svg" alt="Toggle Description" />
            </button>
            <p className="question">{question}</p>
            <p className={`description ${isOpen ? 'hidden' : ''}`}>{answer}</p>
        </div>
    );
};

export default FaqItem;
