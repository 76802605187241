import './style.scss'

interface PropsContainer {
    title: string
    items: { imageUrl: string, title: string, description: string }[]
}

const ContainerItems = ({title, items}: PropsContainer) => {
    return (
        <section className="container-items">
            <h2>{title}</h2>
            {items.map((itemCard, index) => (
                <div className="card" key={index}>
                    <img src={itemCard.imageUrl} alt={title}/>
                    <h3>{itemCard.title}</h3>
                    <p>{itemCard.description}</p>
                </div>
            ))}
        </section>
    )
}

export default ContainerItems
