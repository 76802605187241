import './style.scss'

import TextComponent from "../TextFormatted";
interface PropsCardContainer {
    content: string
}
const CardContainer = ({content}: PropsCardContainer) => {
    return (
        <section className="card-container">
            <p>
                <TextComponent text={content}/>
            </p>
        </section>
    );

}

export default CardContainer;