import './container-items-list.scss'

interface ContainerItemsListProps {
    title: string
    items: { imageUrl: string, title: string, description: string }[]
}

const ContainerItemsList = ({title, items}: ContainerItemsListProps) => {
    return (
        <section className="container-items-list">
            <h2>{title}</h2>
            {items.map((itemCard, index) => (
                <div className="card" key={index}>
                    <div>
                        <h3>{itemCard.title}</h3>
                        <p>{itemCard.description}</p>
                    </div>
                </div>
            ))}
        </section>
    )
}

export default ContainerItemsList
