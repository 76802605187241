import React, {useEffect, useState} from 'react';
import {Simulate} from "react-dom/test-utils";
import './style.scss'
import TagManager from "react-gtm-module";

interface ModalFormProps {
    isOpen: boolean
    onClose:() => void
    onSubmit: (name: string, email: string, phone: string) => void
    openAfterSubmitModal: () => void
}
const Modal = ({ isOpen, onClose, onSubmit, openAfterSubmitModal}: ModalFormProps) => {


    const [name, setname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');


    useEffect(() => {
        // Disparar o evento somente quando o modal está aberto
        if (isOpen) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'view-modal',
                },
            });
        }
    }, [isOpen]);

    const handleSubmit = () => {
        if (validarFormulario(name, email, phone)) {
            onSubmit(name, email, phone);
            resetForm();
            openAfterSubmitModal();

            // const paymentURL = 'https://mpago.la/1fCGYF6'; // Substitua pela URL real
            // try {
            //     window.location.href = paymentURL;
            // } catch (error) {
            //     console.error('Erro ao redirecionar:', error);
            // }
        }
    };

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const validarFormulario = (nome: string, email: string, telefone: string): boolean => {
        let isValid = true;

        if (!nome.trim()) {
            setNameError('Por favor, preencha o nome.');
            isValid = false;
        } else {
            setNameError('');
        }

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailRegex.test(email)) {
            setEmailError('Por favor, insira um endereço de e-mail válido.');
            isValid = false;
        } else {
            setEmailError('');
        }

        const telefoneRegex = /^\d{10,14}$/;
        if (!telefoneRegex.test(telefone)) {
            setPhoneError('Por favor, insira um número de telefone válido.');
            isValid = false;
        } else {
            setPhoneError('');
        }

        return isValid;
    };

    const resetForm = () => {
        // Lógica para apagar os campos do formulário, se necessário
        setname('');
        setEmail('');
        setPhone('');
    };



    return (

        <div className={`modal-container `} style={{ display: isOpen ? 'block' : 'none' }}>
            <div className="card-modal">
                <span className="close-btn" onClick={onClose}><img src="images/close.svg" alt="" /></span>
                <form id="form-contato">
                    <div className="campo">
                        <input
                            type="text"
                            id="nome"
                            name="nome"
                            placeholder="Nome completo"
                            required
                            value={name}
                            onChange={(e) => setname(e.target.value)}
                        />
                        <span className="error-msg">{nameError}</span>
                    </div>
                    <div className="campo">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <span className="error-msg">{emailError}</span>
                    </div>
                    <div className="campo">
                        <input
                            type="tel"
                            id="telefone"
                            name="telefone"
                            placeholder="Telefone"
                            required
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <span className="error-msg">{phoneError}</span>
                    </div>
                    <div>
                        <button className="button" type="button" onClick={handleSubmit}>
                            Quero entrar no método
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Modal;