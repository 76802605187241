import React, {useEffect, useState} from 'react';
import data from 'data/content-desinflama.json'
import Banner from "./Components/Banner";
import CardContainer from "./Components/CardContainer";
import CardReceive from "./Components/CardReceive";
import ContainerDepositions from "./Components/ContainerDepositions";
import ContainerItems from "./Components/ContainerItems";
import ContainerFaq from "./Components/ContainerFaq";
import GoldenButton from "./Components/GoldenButton";
import ContainerCardWithCardTitle from "./Components/ContainerCardWithCardTitle";
import PaymentCard from "./Components/PaymentCard";
import ContainerItemsList from "./Components/ContainerItemsList";
import Modal from "./Components/Modal";
import axios from 'axios'
import TagManager, {TagManagerArgs} from "react-gtm-module";
import AfterSubmitModal from "./Components/AfterSubmitModalProps";
function App() {

    const [isModalFormOpen, setIsModalFormOpen] = useState(false);
    const [isAfterSubmitModalOpen, setIsAfterSubmitModalOpen] = useState(false);


    const openModalForm = () => {
        setIsModalFormOpen(true);
    };

    const closeAfterSubmitModal = () => {
        setIsAfterSubmitModalOpen(false);
    };

    const openAfterSubmitModal = () => {
        setIsAfterSubmitModalOpen(true);
    };

    const closeModalForm = () => {
        setIsModalFormOpen(false);
    };


    useEffect(() => {

        const tagManagerArgs: TagManagerArgs = {
            gtmId: 'GTM-NXQZ2SLP',
            events: {

            },
        };

        TagManager.initialize(tagManagerArgs);
        return () => {
            TagManager.dataLayer({ dataLayer: { event: 'componentUnmount' } });
        };
    }, []);

    const save = (nome: string, email: string, telefone: string) => {

        const dataAtual = new Date().toISOString();
        const formData = {
            "Nome": nome,
            "Email": email,
            "Telefone": telefone,
            "Data": dataAtual
        };
        TagManager.dataLayer({
            dataLayer: {
                event: 'form-submit',
            },
        });
        axios.post('https://api-register-priscila.vercel.app/postUserComData', formData)
            .then((response) => {
                console.log(response.status)
                if (response.status === 200) {
                    closeModalForm();
                } else {
                    alert('Ocorreu um erro ao enviar os dados. Tente novamente.');
                }
            })
            .catch((error) => {
                alert('Ocorreu um erro ao enviar os dados. Tente novamente.');
                console.error(error);
            });


    };

    const json = data;

    // @ts-ignore
    return (


        <section className={"main-container"}>

            <header>
                <Banner {...json.header} titleStyle={{width: "40%"}}/>
            </header>
            <main>
                <CardReceive {...json.main.primarySection.cardReceive} onClickButton={openModalForm} />
                <ContainerDepositions {...json.main.depositions} />
                <ContainerItems {...json.main.solution}/>
                <GoldenButton text="QUERO ENTRAR AGORA" margimBotton="50px" onClick={openModalForm} />
                <section style={{marginBottom:"50px"}}>
                    {json.main.primarySection.cardsProblems.map((card, index) => (
                        <CardContainer key={index} content={card}/>
                    ))}
                </section>
                <ContainerItemsList {...json.main.benefits} />
                <GoldenButton text="QUERO ENTRAR AGORA" margimBotton="50px" onClick={openModalForm} />
                <Banner
                    {...json.main.about}
                    titleStyle={{whiteSpace: "nowrap", fontSize: "26px"}}
                    imageStyle={{width: "80%", maxWidth: "400px"}}
                />
                <PaymentCard {...json.main.Payment} onClick={openModalForm} />
                <ContainerCardWithCardTitle {...json.main.itsNotForYou}/>
                <ContainerCardWithCardTitle {...json.main.itsForYou}/>
                <GoldenButton text="QUERO ENTRAR AGORA" margimBotton="50px" onClick={openModalForm} />
                <ContainerFaq {...json.main.faq}/>
                <Modal isOpen={isModalFormOpen} onClose={closeModalForm} onSubmit={save} openAfterSubmitModal={openAfterSubmitModal} />
                <AfterSubmitModal
                    title={json.main.afterSubmitSection.title}
                    description={json.main.afterSubmitSection.description}
                    isOpen={isAfterSubmitModalOpen}
                    onClose={closeAfterSubmitModal}
                />
            </main>
            <footer>
                <div>
                    <a href="https://instagram.com/prii.soaress?igshid=MzRlODBiNWFlZA==" target="_blank">
                        <img src="images/iconerodapeinstagram.svg" alt="Botao instagram priscila"/>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100005345100243&mibextid=LQQJ4d" target="_blank">
                        <img src="images/iconerodapefacebook.svg" alt="Botao facebook priscila"/>
                    </a>
                    <a href="https://wa.me/5519971446784" target="_blank">
                        <img src="images/iconerodapewhatsapp.svg" alt="Botao w      hatsapp priscila"/>
                    </a>
                </div>
                <span>powered by <a href="https://stratosphere.website" target="_blank">Stratosphere</a></span>
            </footer>
        </section>


    );
}

export default App;
