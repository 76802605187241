import './style.scss'
import GoldenButton from "../GoldenButton";
import TextComponent from "../TextFormatted";

interface PropsCardReceive {
    title: string
    subtitle: string
    descWarranty: string
    descSecondary: string
    textButton: string
    onClickButton?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const CardReceive = ({title, subtitle, textButton, descWarranty, descSecondary, onClickButton}: PropsCardReceive) => {
    return (
        <section className="card-receive">
            <h2><TextComponent text={title}/></h2>
            <div>
                <p>{subtitle}</p>
            </div>
            <GoldenButton text={textButton} onClick={onClickButton} />
            <p id="warranty">{descWarranty}</p>
            <p>{descSecondary}</p>
        </section>
    )
}

export default CardReceive;