import './style.scss'
import VideoComponent from "../VideoComponent";

interface PropsContainerDeposition {
    title: string
    videos: { posterUrl: string, srcUrl: string }[]
}

const ContainerDepositions = ({title, videos}: PropsContainerDeposition) => {
    return (
        <section className="container-depositions">
            <h2>{title}</h2>
            <div>
                {videos.map((video, index) => (
                    <VideoComponent key={index} posterUrl={video.posterUrl} srcUrl={video.srcUrl} />
                ))}
            </div>
        </section>
    )
}

export default ContainerDepositions